import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route} from 'react-router-dom';
import EncounterCalculator from './components/EncounterCalculator/EncounterCalculator';
import { Container } from 'react-bootstrap';
import DankNav from './components/DankNav/DankNav';
import WeatherGenerator from './components/WeatherGenerator/WeatherGenerator'

const Root = () => {
  return (
    <Container>
      <BrowserRouter>
        <div>
          <Route exact path="/" component={EncounterCalculator}/>
          <Route path="/weather" component={WeatherGenerator}/>
          {/* <Route path="/user/:id" component={UserProfile}/> */}
        </div>
      </BrowserRouter>
    </Container>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <DankNav/>
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
      integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
      crossOrigin="anonymous"
    />
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
