import React, {Component} from 'react';
import './WeatherGenerator.css';
import {Row} from 'react-bootstrap'

class WeatherGenerator extends Component {
  constructor(props) {
    super(props);

    this.state = {
        temp: 'Windy af',
        wind: '',
        precip: '',
    };

    this.getRandomInt = this.getRandomInt.bind(this);
    this.generateWeather = this.generateWeather.bind(this);
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  }
  
  generateWeather() {
    let tempRandom = this.getRandomInt(1, 21);
    let windRandom = this.getRandomInt(1, 21);
    let precipRandom = this.getRandomInt(1, 21);

    if (tempRandom <= 14) {
      this.setState({ temp: 'Normal temperature' });
    } else if (tempRandom > 14 && tempRandom <= 17) {
      let degreeRandom = this.getRandomInt(1, 5) * 10;
      this.setState({ temp: degreeRandom + '° colder than normal' });
    } else if (tempRandom > 17) {
      let degreeRandom = this.getRandomInt(1, 5) * 10;
      this.setState({ temp: degreeRandom + '° hotter than normal' });
    }

    if (windRandom <= 12) {
      this.setState({ wind: 'No wind' });
    } else if (windRandom > 12 && windRandom <= 17) {
      this.setState({ wind: 'Light wind' });
    } else if (windRandom > 17) {
      this.setState({ wind: 'Strong wind' });
    }

    if (precipRandom <= 12) {
      this.setState({ precip: 'No Precipitation' });
    } else if (precipRandom > 12 && precipRandom <= 17) {
      this.setState({ precip: 'Light rain or snowfall' });
    } else if (precipRandom > 17) {
      this.setState({ precip: 'Heavy rain or snowfall' });
    }
  }

  render() {

    return (
      <div className="top-1">
        <h1 className="text-center">Weather Randomizer</h1>
        <Row>
          <div className="text-center">
            <button type="button" className="btn btn-success btn-lg text-center" onClick={this.generateWeather}>Generate Weather</button>
          </div>
        </Row>
        <div className="text-center top-3">
          <h2>{(this.state.temp != null ? this.state.temp : '')}</h2>
          <h2>{(this.state.wind != null ? this.state.wind : '')}</h2>
          <h2>{(this.state.precip != null ? this.state.precip : '')}</h2>
        </div>
      </div>
    )
  }
}

export default WeatherGenerator;
