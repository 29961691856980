import React, {Component} from 'react';
import './EncounterCalculator.css';
import { Row, Col } from 'react-bootstrap';
import difficultyTable from '../../difficultyTable'

class EncounterCalculator extends Component {
  constructor(props) {
    super(props);

    this.state = {
        numPlayers: 1,
        playerLevel: 1,
        numEnemies: 1,
        totalEnemyXp: 0,
        xpToNextDifficulty: 0,
        difficulty: ''
    };

    this.onEnemyXpChanged = this.onEnemyXpChanged.bind(this);
  }

  componentDidMount() {
    this.onEnemyXpChanged();
  }

  async onNumPlayersChanged(numPlayers) {
    if (numPlayers > 10) {
      numPlayers = 10;
      return;
    }
    this.setState({numPlayers}, this.onEnemyXpChanged);
  }

  onPlayerLevelChanged(playerLevel) {
    if (playerLevel > 20) {
      playerLevel = 20;
      return;
    }
    this.setState({playerLevel}, this.onEnemyXpChanged);
  }

  onNumEnemiesChanged(numEnemies) {
    this.setState({numEnemies}, this.onEnemyXpChanged);
  }

  onEnemyXpChanged() {
    let inputs = document.querySelectorAll('[id^="enemy"]');
    let total = 0;
    inputs.forEach(input => {
      let value = parseInt(input.value, 10) || 0;
      total += value;
    });

    this.setState({totalEnemyXp: total})
    let {numPlayers, playerLevel} = this.state;

    if (playerLevel == 0 || playerLevel > 20) {
      playerLevel = 1;
      return;
    }

    let xpArray = difficultyTable[playerLevel - 1];
    /* XP Array Indices
     * 0 -- Easy
     * 1 -- Medium
     * 2 -- Hard
     * 3 -- Deadly
     */
    console.log(difficultyTable.length);
    if (total < xpArray[1] * numPlayers) {
      this.setState({difficulty: "Easy", xpToNextDifficulty: (xpArray[1] * numPlayers) - total});
    } else if (total < xpArray[2] * numPlayers) {
      this.setState({difficulty: "Medium", xpToNextDifficulty: (xpArray[2] * numPlayers) - total});
    } else if (total < xpArray[3] * numPlayers) {
      this.setState({difficulty: "Hard", xpToNextDifficulty: (xpArray[3] * numPlayers) - total});
    } else {
      this.setState({difficulty: "Deadly", xpToNextDifficulty: null});
    }
  }

  displayXpInputs() {
    let {numEnemies} = this.state;
    let divs = [];
    for (let i = 0; i < numEnemies; i++) {
      divs.push(
        <div key={i}>
          <p>
            Enemy {i+1} XP: &nbsp;
            <input type="text" id={"enemy"+i} onChange={this.onEnemyXpChanged}/>
          </p>
        </div>
      )
    } 
    return divs;
  }

  render() {

    return (
      <div className="top-1">
        <h1 className="text-center">Encounter Calculator</h1>
        <Row>
          <Col>
            <h4 className="top-1">Num Players:&nbsp;
              <input type="number" className="tiny-input"
                  min="1"
                  max="10"
                  value={this.state.numPlayers} 
                  onChange={changeEvent => this.onNumPlayersChanged(changeEvent.target.value)}
              />
            </h4>
            <input type="range" min="1" max="10" value={this.state.numPlayers} onChange={changeEvent => this.onNumPlayersChanged(changeEvent.target.value)}/>
          </Col>
          <Col>
            <h4 className="top-1">Player Level:&nbsp;
            <input type="number" className="tiny-input"
                min="1"
                max="20"
                value={this.state.playerLevel} 
                onChange={changeEvent => this.onPlayerLevelChanged(changeEvent.target.value)}
              />
            </h4>
            <input type="range" min="1" max="20" value={this.state.playerLevel} onChange={changeEvent => this.onPlayerLevelChanged(changeEvent.target.value)}/>
          </Col>
          <Col>
            <h4 className="top-1">
              Num Enemies:&nbsp;
              <input type="number" className="tiny-input"
                min="1"
                max="50"
                value={this.state.numEnemies} 
                onChange={changeEvent => this.onNumEnemiesChanged(changeEvent.target.value)}
              />
              </h4>
            <input type="range" min="1" max="50" value={this.state.numEnemies} onChange={changeEvent => this.onNumEnemiesChanged(changeEvent.target.value)}/>
          </Col>
        </Row>
        <div className="top-2"/> {/* lol "padding" */}
        <Row>
          <Col>
            {this.displayXpInputs()}  
          </Col>
          <Col/>
          <Col>
            <h2>Total Enemy XP: {this.state.totalEnemyXp}</h2>
            <h2 className={"top-1 diff-" + this.state.difficulty.toLowerCase()}>
              Difficulty: {this.state.difficulty}
            </h2>
            {
              (this.state.xpToNextDifficulty != null) ? (
                <h4>
                  XP to next rank: {this.state.xpToNextDifficulty }
                </h4>
              ) : ''
            }
          </Col>
        </Row>
      </div>
    )
  }
}
export default EncounterCalculator;
